import "./services.css";
import Sectionresto from "../../components/section-resto";
import Sectionvisite from "../../components/section-visite";
import Sectiontrain from "../../components/section-train";

const Prestation = () => {
  return (
    <div className="prestation-du-zoo">
      <Sectionresto />
      <Sectionvisite />
      <Sectiontrain/>
      
  </div>
  
      
  
    
  );
};

export default Prestation;
