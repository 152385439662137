import"./mission.css";
import Sectioneducation from "../../components/section-education";
import Sectionconservation from "../../components/section-conservation";
import Sectionrecherche from "../../components/section-recherche";

const Mission = () => {
  return (
    <div className="mission-du-zoo">
      <Sectioneducation />
      <Sectionconservation />
      <Sectionrecherche/>
      
  </div>
      
  
    
  );
};

export default Mission;